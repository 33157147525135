import React, { useState } from 'react';
import { DOBField } from '../DOBField/DOBField';
import { AddressFields } from '../AddressFields/AddressFields';
import { LicenseFields } from '../LicenseFields/LicenseFields';

interface ProfileFormProps {
  profile_id: number;
  profile: {
    has_fincen_id: boolean;
    fincen_id: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    suffix: string;
    date_of_birth: string;
    phone_country_code: string;
    phone: string;
    type_of_address: string;
    street_address: string;
    address_line_2: string;
    city: string;
    country: string;
    state: string;
    zip: string;
    type_of_license: string;
    license_number: string;
    license_country: string;
    license_state: string;
    identification_expiration_date: string;
  };
  countryCodes: string[];
  form_authenticity_token: string;
  presignedUrl?: string;
}

export const ProfileForm: React.FC<ProfileFormProps> = ({ profile_id, profile, countryCodes, form_authenticity_token, presignedUrl }) => {
  const [formData, setFormData] = useState({
    ...profile,
    has_fincen_id: profile.has_fincen_id || false,
    fincen_id: profile.fincen_id || '',
    first_name: profile.first_name || '',
    middle_name: profile.middle_name || '',
    last_name: profile.last_name || '',
    suffix: profile.suffix || '',
    date_of_birth: profile.date_of_birth || '',
    phone_country_code: profile.phone_country_code || '',
    phone: profile.phone || '',
    type_of_address: profile.type_of_address || '',
    street_address: profile.street_address || '',
    address_line_2: profile.address_line_2 || '',
    city: profile.city || '',
    country: profile.country || '',
    state: profile.state || '',
    zip: profile.zip || '',
    type_of_license: profile.type_of_license || '',
    license_number: profile.license_number || '',
    license_country: profile.license_country || '',
    license_state: profile.license_state || '',
    identification_expiration_date: profile.identification_expiration_date || '',
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [identificationFront, setIdentificationFront] = useState<File | null>(null);

  const fieldsDisabled = formData.has_fincen_id;
  const fieldsRequired = !formData.has_fincen_id;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setIdentificationFront(e.target.files[0]);
    }
  };

  const handleAddressFieldChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleLicenseFieldChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleDOBFieldChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    if (fieldsRequired) {
      if (!formData.first_name) newErrors.first_name = "First Name is required";
      if (!formData.last_name) newErrors.last_name = "Last Name is required";
      if (!formData.date_of_birth) newErrors.date_of_birth = "Date of Birth is required";
      if (!formData.phone_country_code) newErrors.phone_country_code = "Phone Country Code is required";
      if (!formData.phone) newErrors.phone = "Phone Number is required";
      if (!formData.type_of_address) newErrors.type_of_address = "Type of Address is required";
      if (!formData.street_address) newErrors.street_address = "Street Address is required";
      if (!formData.city) newErrors.city = "City is required";
      if (!formData.state) newErrors.state = "State/Province is required";
      if (!formData.zip) newErrors.zip = "ZIP Code is required";
      if (!formData.country) newErrors.country = "Country is required";
      if (!formData.type_of_license) newErrors.type_of_license = "Type of License is required";
    }
    if (formData.has_fincen_id && !formData.fincen_id) newErrors.fincen_id = "FinCEN ID is required when 'Has FinCEN ID' is true";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataToSubmit = new FormData();

      // Append all form data
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSubmit.append(`profile[${key}]`, value as string);
      });

      // Append the file if it exists
      if (identificationFront) {
        formDataToSubmit.append('profile[identification_front]', identificationFront);
      }

      formDataToSubmit.append('authenticity_token', form_authenticity_token);

      const submitUrl = `/profiles/${profile_id}.json`;

      console.log('Form Data to Submit:');
      for (const [key, value] of formDataToSubmit.entries()) {
        console.log(`${key}: ${value}`);
      }

      const method = "PATCH";

      fetch(submitUrl, {
        method: method,
        body: formDataToSubmit,
        headers: {
          'X-CSRF-Token': form_authenticity_token,
        },
      })
        .then(response => {
          console.log('Response:', response);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('Submission successful', data);
          const redirectUrl = submitUrl.replace('.json', '');
          window.location.href = redirectUrl;
        })
        .catch(error => {
          console.error('Error:', error);
          setErrors({ submission: error.message || 'An error occurred while submitting the form.' });
        });
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit} className="form">
                {Object.keys(errors).length > 0 && (
                  <div className="alert alert-danger">
                    <h2>{Object.keys(errors).length} error(s) prevented this form from being submitted:</h2>
                    <ul>
                      {Object.entries(errors).map(([key, value]) => (
                        <li key={key}>{value}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="row">
                  <div className="col-6">
                    <div className="form-group mb-2">
                      <label className="form-label">Does this Company Applicant have a FinCEN ID?</label>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="has_fincen_id_true"
                            name="has_fincen_id"
                            checked={formData.has_fincen_id === true}
                            onChange={() => setFormData({ ...formData, has_fincen_id: true })}
                          />
                          <label className="form-check-label" htmlFor="has_fincen_id_true">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="has_fincen_id_false"
                            name="has_fincen_id"
                            checked={formData.has_fincen_id === false}
                            onChange={() => setFormData({ ...formData, has_fincen_id: false })}
                          />
                          <label className="form-check-label" htmlFor="has_fincen_id_false">No</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="profile_fincen_id"
                        name="fincen_id"
                        value={formData.fincen_id}
                        onChange={handleInputChange}
                        disabled={!formData.has_fincen_id}
                        required={formData.has_fincen_id}
                      />
                      <label htmlFor="profile_fincen_id">FinCEN ID</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="profile_first_name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        required={fieldsRequired}
                        disabled={fieldsDisabled}
                      />
                      <label htmlFor="profile_first_name">First Name*</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="profile_middle_name"
                        name="middle_name"
                        value={formData.middle_name}
                        onChange={handleInputChange}
                        disabled={fieldsDisabled}
                      />
                      <label htmlFor="profile_middle_name">Middle Name</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="profile_last_name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        required={fieldsRequired}
                        disabled={fieldsDisabled}
                      />
                      <label htmlFor="profile_last_name">Last Name*</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="profile_suffix"
                        name="suffix"
                        value={formData.suffix}
                        onChange={handleInputChange}
                        disabled={fieldsDisabled}
                      />
                      <label htmlFor="profile_suffix">Suffix</label>
                    </div>
                    <DOBField
                      selectedDateOfBirth={formData.date_of_birth}
                      onChange={handleDOBFieldChange}
                      modelName="profile"
                      disabled={fieldsDisabled}
                      required={fieldsRequired}
                    />
                    <div className="form-floating mb-2">
                      <select
                        className="form-select"
                        id="profile_phone_country_code"
                        name="phone_country_code"
                        value={formData.phone_country_code}
                        onChange={handleInputChange}
                        required={fieldsRequired}
                        disabled={fieldsDisabled}
                      >
                        <option value="">Select country code</option>
                          {countryCodes.map((code) => (
                            <option key={code[0]} value={code[0]}>{code[0]}</option>
                          ))}
                      </select>
                      <label htmlFor="profile_phone_country_code">Phone Country Code*</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="profile_phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required={fieldsRequired}
                        disabled={fieldsDisabled}
                      />
                      <label htmlFor="profile_phone">Phone Number*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-floating mb-2">
                      <select
                        className="form-select form-select-lg"
                        id="profile_type_of_address"
                        name="type_of_address"
                        value={formData.type_of_address}
                        onChange={handleInputChange}
                        required={fieldsRequired}
                        disabled={fieldsDisabled}
                        style={{ fontSize: '1rem !important' }}
                      >
                        <option value="">Select Address Type</option>
                        <option value="Residential">Residential</option>
                        <option value="Business">Business</option>
                      </select>
                      <label htmlFor="profile_type_of_address">Type of Address*</label>
                    </div>
                    <AddressFields
                      selectedStreetAddress={formData.street_address}
                      selectedAddressLine2={formData.address_line_2}
                      selectedCity={formData.city}
                      selectedCountry={formData.country}
                      selectedState={formData.state}
                      selectedZip={formData.zip}
                      onChange={handleAddressFieldChange}
                      modelName="profile"
                      fieldsDisabled={fieldsDisabled}
                      fieldsRequired={fieldsRequired}
                    />
                  </div>
                </div>
                <div className="bg-light p-3 mt-3" style={{ border: '1px solid black' }}>
                  <div className="form-group">
                    <strong><label className="form-label">Form of Identification*</label></strong>
                    <p>Please select one of the following valid forms of identification. At least one of the following is required.</p>
                    <LicenseFields
                      modelName="profile"
                      selectedTypeOfLicense={formData.type_of_license}
                      selectedLicenseNumber={formData.license_number}
                      selectedLicenseCountry={formData.license_country}
                      selectedLicenseState={formData.license_state}
                      selectedIdentificationExpirationDate={formData.identification_expiration_date}
                      onChange={handleLicenseFieldChange}
                      types_of_licenses={[]} // Add your license types here
                      fieldsDisabled={fieldsDisabled}
                      fieldsRequired={fieldsRequired}
                    />
                  </div>
                  <div className="row pt-3">
                    <p>It is required that you scan and provide an image of this identification.</p>
                    <div className="col-6">
                      <div className="form-group">
                        <strong><label htmlFor="profile_identification_front" className="form-label">Identification Front{!presignedUrl && '*'}</label></strong>
                        <p><small>The image must be less than 4MB and in JPEG, PNG or PDF format.</small></p>
                        <input
                          type="file"
                          className="form-control"
                          id="profile_identification_front"
                          name="identification_front"
                          onChange={handleFileChange}
                          required={!presignedUrl && fieldsRequired}
                          disabled={fieldsDisabled}
                          accept="image/png, image/jpeg, image/jpg, application/pdf"
                        />
                      </div>
                      {presignedUrl && (
                        <div className="form-group mt-3">
                          <strong>Current Identification Front:</strong>
                          <div className="mt-2">
                            <img
                              src={presignedUrl}
                              alt="Current Identification Front"
                              style={{ width: '500px' }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group text-center mt-3">
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
