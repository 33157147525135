import React, { useState } from 'react';
import { DOBField } from '../DOBField/DOBField';
import { AddressFields } from '../AddressFields/AddressFields';
import { LicenseFields } from '../LicenseFields/LicenseFields';

interface ProfileFormProps {
  profile_id: number;
  profile: {
    additional_app_has_fincen_id: boolean;
    additional_app_fincen_id: string;
    additional_app_first_name: string;
    additional_app_middle_name: string;
    additional_app_last_name: string;
    additional_app_suffix: string;
    additional_app_date_of_birth: string;
    additional_app_phone_country_code: string;
    additional_app_phone: string;
    additional_app_type_of_address: string;
    additional_app_street_address: string;
    additional_app_address_line_2: string;
    additional_app_city: string;
    additional_app_country: string;
    additional_app_state: string;
    additional_app_zip: string;
    additional_app_type_of_license: string;
    additional_app_license_number: string;
    additional_app_license_country: string;
    additional_app_license_state: string;
    additional_app_identification_expiration_date: string;
  };
  countryCodes: string[];
  form_authenticity_token: string;
  presignedUrl?: string;
}

export const ProfileAdditionalForm: React.FC<ProfileFormProps> = ({ profile_id, profile, countryCodes, form_authenticity_token, presignedUrl }) => {
  const [formData, setFormData] = useState({
    ...profile,
    additional_app_has_fincen_id: profile.additional_app_has_fincen_id || false,
    additional_app_fincen_id: profile.additional_app_fincen_id || '',
    additional_app_first_name: profile.additional_app_first_name || '',
    additional_app_middle_name: profile.additional_app_middle_name || '',
    additional_app_last_name: profile.additional_app_last_name || '',
    additional_app_suffix: profile.additional_app_suffix || '',
    additional_app_date_of_birth: profile.additional_app_date_of_birth || '',
    additional_app_phone_country_code: profile.additional_app_phone_country_code || '',
    additional_app_phone: profile.additional_app_phone || '',
    additional_app_type_of_address: profile.additional_app_type_of_address || '',
    additional_app_street_address: profile.additional_app_street_address || '',
    additional_app_address_line_2: profile.additional_app_address_line_2 || '',
    additional_app_city: profile.additional_app_city || '',
    additional_app_country: profile.additional_app_country || '',
    additional_app_state: profile.additional_app_state || '',
    additional_app_zip: profile.additional_app_zip || '',
    additional_app_type_of_license: profile.additional_app_type_of_license || '',
    additional_app_license_number: profile.additional_app_license_number || '',
    additional_app_license_country: profile.additional_app_license_country || '',
    additional_app_license_state: profile.additional_app_license_state || '',
    additional_app_identification_expiration_date: profile.additional_app_identification_expiration_date || '',
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [identificationFront, setIdentificationFront] = useState<File | null>(null);

  const fieldsDisabled = formData.additional_app_has_fincen_id;
  const fieldsRequired = !formData.additional_app_has_fincen_id;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    setFormData({ ...formData, [name]: newValue });

    console.log('formData', formData);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setIdentificationFront(e.target.files[0]);
    }
  };

  const handleAddressFieldChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleLicenseFieldChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleDOBFieldChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    if (fieldsRequired) {
      if (!formData.additional_app_first_name.trim()) newErrors.additional_app_first_name = "First Name is required";
      if (!formData.additional_app_last_name.trim()) newErrors.additional_app_last_name = "Last Name is required";
      if (!formData.additional_app_date_of_birth.trim()) newErrors.additional_app_date_of_birth = "Date of Birth is required";
      if (!formData.additional_app_phone_country_code.trim()) newErrors.additional_app_phone_country_code = "Phone Country Code is required";
      if (!formData.additional_app_phone.trim()) newErrors.additional_app_phone = "Phone Number is required";
      if (!formData.additional_app_type_of_address.trim()) newErrors.additional_app_type_of_address = "Type of Address is required";
      if (!formData.additional_app_street_address.trim()) newErrors.additional_app_street_address = "Street Address is required";
      if (!formData.additional_app_city.trim()) newErrors.additional_app_city = "City is required";
      if (!formData.additional_app_state.trim()) newErrors.additional_app_state = "State/Province is required";
      if (!formData.additional_app_zip.trim()) newErrors.additional_app_zip = "ZIP Code is required";
      if (!formData.additional_app_country.trim()) newErrors.additional_app_country = "Country is required";
      if (!formData.additional_app_type_of_license.trim()) newErrors.additional_app_type_of_license = "Type of License is required";
    }
    if (formData.additional_app_has_fincen_id && !formData.additional_app_fincen_id.trim()) newErrors.additional_app_fincen_id = "FinCEN ID is required when 'Has FinCEN ID' is true";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataToSubmit = new FormData();

      // Append all form data
      Object.entries(formData).forEach(([key, value]) => {
        if (value !== '') {
          formDataToSubmit.append(`profile[${key}]`, value as string);
        }
      });

      // Append the file if it exists
      if (identificationFront) {
        formDataToSubmit.append('profile[additional_app_identification_front]', identificationFront);
      }

      formDataToSubmit.append('authenticity_token', form_authenticity_token);

      const submitUrl = `/profiles/${profile_id}.json`;

      console.log('Form Data to Submit:', formDataToSubmit.getAll('profile[additional_app_first_name]'));

      const method = "PATCH";

      fetch(submitUrl, {
        method: method,
        body: formDataToSubmit,
        headers: {
          'X-CSRF-Token': form_authenticity_token,
        },
      })
        .then(response => {
          console.log('Response:', response);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('Submission successful', data);
          const redirectUrl = submitUrl.replace('.json', '');
          window.location.href = redirectUrl;
        })
        .catch(error => {
          console.error('Error:', error);
          setErrors({ submission: error.message || 'An error occurred while submitting the form.' });
        });
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit} className="form">
                {Object.keys(errors).length > 0 && (
                  <div className="alert alert-danger">
                    <h2>{Object.keys(errors).length} error(s) prevented this form from being submitted:</h2>
                    <ul>
                      {Object.entries(errors).map(([key, value]) => (
                        <li key={key}>{value}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="row">
                  <div className="col-6">
                    <div className="form-group mb-2">
                      <label className="form-label">Does this Company Applicant have a FinCEN ID?</label>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="additional_app_has_fincen_id_true"
                            name="additional_app_has_fincen_id"
                            checked={formData.additional_app_has_fincen_id === true}
                            onChange={() => setFormData({ ...formData, additional_app_has_fincen_id: true })}
                          />
                          <label className="form-check-label" htmlFor="additional_app_has_fincen_id_true">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="additional_app_has_fincen_id_false"
                            name="additional_app_has_fincen_id"
                            checked={formData.additional_app_has_fincen_id === false}
                            onChange={() => setFormData({ ...formData, additional_app_has_fincen_id: false })}
                          />
                          <label className="form-check-label" htmlFor="additional_app_has_fincen_id_false">No</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="profile_additional_app_fincen_id"
                        name="additional_app_fincen_id"
                        value={formData.additional_app_fincen_id}
                        onChange={handleInputChange}
                        disabled={!formData.additional_app_has_fincen_id}
                        required={formData.additional_app_has_fincen_id}
                      />
                      <label htmlFor="profile_additional_app_fincen_id">FinCEN ID</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="profile_additional_app_first_name"
                        name="additional_app_first_name"
                        value={formData.additional_app_first_name}
                        onChange={handleInputChange}
                        required={fieldsRequired}
                        disabled={fieldsDisabled}
                      />
                      <label htmlFor="profile_additional_app_first_name">First Name*</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="profile_additional_app_middle_name"
                        name="additional_app_middle_name"
                        value={formData.additional_app_middle_name}
                        onChange={handleInputChange}
                        disabled={fieldsDisabled}
                      />
                      <label htmlFor="profile_additional_app_middle_name">Middle Name</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="profile_additional_app_last_name"
                        name="additional_app_last_name"
                        value={formData.additional_app_last_name}
                        onChange={handleInputChange}
                        required={fieldsRequired}
                        disabled={fieldsDisabled}
                      />
                      <label htmlFor="profile_additional_app_last_name">Last Name*</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="profile_additional_app_suffix"
                        name="additional_app_suffix"
                        value={formData.additional_app_suffix}
                        onChange={handleInputChange}
                        disabled={fieldsDisabled}
                      />
                      <label htmlFor="profile_additional_app_suffix">Suffix</label>
                    </div>
                    <DOBField
                      selectedDateOfBirth={formData.additional_app_date_of_birth}
                      onChange={handleDOBFieldChange}
                      modelName="profile"
                      fieldName="additional_app_date_of_birth"
                      disabled={fieldsDisabled}
                      required={fieldsRequired}
                      value={formData.additional_app_date_of_birth}
                    />
                    <div className="form-floating mb-2">
                      <select
                        className="form-select"
                        id="profile_additional_app_phone_country_code"
                        name="additional_app_phone_country_code"
                        value={formData.additional_app_phone_country_code}
                        onChange={handleInputChange}
                        required={fieldsRequired}
                        disabled={fieldsDisabled}
                      >
                        <option value="">Select country code</option>
                        {countryCodes.map((code) => (
                          <option key={code[0]} value={code[0]}>{code[0]}</option>
                        ))}
                      </select>
                      <label htmlFor="profile_additional_app_phone_country_code">Phone Country Code*</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="profile_additional_app_phone"
                        name="additional_app_phone"
                        value={formData.additional_app_phone}
                        onChange={handleInputChange}
                        required={fieldsRequired}
                        disabled={fieldsDisabled}
                      />
                      <label htmlFor="profile_additional_app_phone">Phone Number*</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-floating mb-2">
                      <select
                        className="form-select form-select-lg"
                        id="profile_additional_app_type_of_address"
                        name="additional_app_type_of_address"
                        value={formData.additional_app_type_of_address}
                        onChange={handleInputChange}
                        required={fieldsRequired}
                        disabled={fieldsDisabled}
                        style={{ fontSize: '1rem !important' }}
                      >
                        <option value="">Select Address Type</option>
                        <option value="Residential">Residential</option>
                        <option value="Business">Business</option>
                      </select>
                      <label htmlFor="profile_additional_app_type_of_address">Type of Address*</label>
                    </div>
                    <AddressFields
                      selectedStreetAddress={formData.additional_app_street_address}
                      selectedAddressLine2={formData.additional_app_address_line_2}
                      selectedCity={formData.additional_app_city}
                      selectedCountry={formData.additional_app_country}
                      selectedState={formData.additional_app_state}
                      selectedZip={formData.additional_app_zip}
                      onChange={handleAddressFieldChange}
                      modelName="profile"
                      prefix="additional_app_"
                      fieldsDisabled={fieldsDisabled}
                      fieldsRequired={fieldsRequired}
                    />
                  </div>
                </div>
                <div className="bg-light p-3 mt-3" style={{ border: '1px solid black' }}>
                  <div className="form-group">
                    <strong><label className="form-label">Form of Identification*</label></strong>
                    <p>Please select one of the following valid forms of identification. At least one of the following is required.</p>
                    <LicenseFields
                      modelName="profile"
                      prefix="additional_app_"
                      selectedTypeOfLicense={formData.additional_app_type_of_license}
                      selectedLicenseNumber={formData.additional_app_license_number}
                      selectedLicenseCountry={formData.additional_app_license_country}
                      selectedLicenseState={formData.additional_app_license_state}
                      selectedIdentificationExpirationDate={formData.additional_app_identification_expiration_date}
                      onChange={handleLicenseFieldChange}
                      types_of_licenses={[]} // Add your license types here
                      fieldsDisabled={fieldsDisabled}
                      fieldsRequired={fieldsRequired}
                    />
                  </div>
                  <div className="row pt-3">
                    <p>It is required that you scan and provide an image of this identification.</p>
                    <div className="col-6">
                      <div className="form-group">
                        <strong><label htmlFor="profile_additional_app_identification_front" className="form-label">Identification Front{!presignedUrl && '*'}</label></strong>
                        <p><small>The image must be less than 4MB and in JPEG, PNG or PDF format.</small></p>
                        <input
                          type="file"
                          className="form-control"
                          id="profile_additional_app_identification_front"
                          name="additional_app_identification_front"
                          onChange={handleFileChange}
                          required={!presignedUrl && fieldsRequired}
                          disabled={fieldsDisabled}
                          accept="image/png, image/jpeg, image/jpg, application/pdf"
                        />
                      </div>
                      {presignedUrl && (
                        <div className="form-group mt-3">
                          <strong>Current Identification Front:</strong>
                          <div className="mt-2">
                            <img
                              src={presignedUrl}
                              alt="Current Identification Front"
                              style={{ width: '500px' }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group text-center mt-3">
                  <button type="submit" className="btn btn-primary">Continue</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
